import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

import { CaretDownOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'

const CustomSelectWrapper = styled(Select)`
  && {
    .ant-select-selector {      
      color: #263238;
      border-radius: 2rem;
      border: 0.05rem solid #c9c9c9 !important;
      font-size: 1rem !important;
      align-items: center;
      padding-left: 1rem;
      padding-block: 0.5rem;
      background: #fff !important;
      cursor: pointer !important;

      &:hover {
        border: 0.05rem solid #fff !important;
      }
  
      .ant-select-selection-item {
        color: #333 !important;
        cursor: pointer !important;
        font-weight: 600 !important;
      }
    }

    @media screen and (max-width: 480px) {
      .ant-select-selector {
        padding-block: 0.2rem;
      }
    }
  }
`
// Using Ant design to create a custom select for all selects
const CustomSelect = (props) => {
  const dropdownStyles = {
    backgroundColor: '#fff',
    color: '#333',
  }

  return (
    <CustomSelectWrapper
      {...props}
      popupClassName="custom-select-dropdown"
      dropdownStyle={dropdownStyles}
      suffixIcon={props.disabled ? null : <CaretDownOutlined style={{ color: '#333' }} />}
      disabled={props.disabled}
    />
  )
}

const languageNames = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' }
]

const LanguageSwitcher = () => {
  let selectedLang
  if (localStorage.getItem('i18nextLng') === 'en-GB'
      || localStorage.getItem('i18nextLng') === 'en-US'
      || localStorage.getItem('i18nextLng') === 'en-IN'
      || localStorage.getItem('i18nextLng') === 'en-AU')
  {
    selectedLang = 'en'
  } else {
    selectedLang = localStorage.getItem('i18nextLng')
  }
  const { i18n } = useTranslation()

  const changeLanguage = (selectedLocale) => {
    i18n.changeLanguage(selectedLocale)
  };

  return (
    <CustomSelect
      defaultValue={selectedLang}
      onChange={changeLanguage}
      options={languageNames}
      style={{ height: 'auto' }}
    />
  );
};

export default LanguageSwitcher;
